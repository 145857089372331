<template>
  <div class="item" @click="toPage">
    <span class="name">{{item.title}}</span>
    <span class="time">{{item.createTime}}</span>
  </div>
</template>

<script>
export default {
  props: {
    // title: String,
    // time: String,
    item: Object
  },
  methods: {
    toPage() {
      this.$emit("toPage", this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  padding: 8px 0;
  display: flex;
  .name {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time {
    flex-basis: 50px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
    text-align: right;
  }
}
</style>